import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import moment from "moment";
import i18next from "i18next";
import {
    Alert
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import Skeleton from "react-loading-skeleton";
import * as Sentry from "@sentry/react";

import ShopContext from "../../../../context/internal/ShopManager";
import AccountEmptyStateCard from "../components/AccountEmptyStateCard";
import AccountCourseCard from "./components/AccountCourseCard";

function sortCourses(course1, course2) {
    if(!course1 || !course2) {
        return 0;
    }

    if(!course1.access || !course2.access) {
        return 0;
    }

    // Courses the user no longer has access to should be sorted after the ones they do have access to.
    if(course1.access.hasAccess !== course2.access.hasAccess) {
        return course1.access.hasAccess ? -1 : 1;
    }

    // If one of the courses has no expiration date, it should be sorted after the one that does.
    const expirationDate1 = course1.access.expirationDate;
    const expirationDate2 = course2.access.expirationDate;
    if((expirationDate1 !== null) !== (expirationDate2 !== null)) {
        return expirationDate1 === null ? 1 : -1;
    }

    // If both courses have no expiration date, they should be sorted by title.
    if(expirationDate1 === null && expirationDate2 === null) {
        if(!course1.title) {
            Sentry.captureException(new Error("Course has no title: " + JSON.stringify(course1)));
        }
        if(!course2.title) {
            Sentry.captureException(new Error("Course has no title: " + JSON.stringify(course2)));
        }
        if(!course1.title || !course2.title) {
            return 0;
        }
        return course1.title.localeCompare(course2.title);
    }

    // If both courses have an expiration date, they should be sorted by expiration date. The one that expires first should be sorted first.
    const momentExpirationDate1 = moment(expirationDate1);
    const momentExpirationDate2 = moment(expirationDate2);
    if(momentExpirationDate1.isBefore(momentExpirationDate2)) {
        return 1;
    }
    if(momentExpirationDate1.isAfter(momentExpirationDate2)) {
        return -1;
    }
    return 0;
}

function AccountCourses() {
    const shopContext = useContext(ShopContext);
    const [courses, setCourses] = useState(null);
    const [error, setError] = useState(null);

    const refreshCourses = useCallback(async () => {
        setCourses(null);
        try {
            const response = await shopContext.shopApi.post("/getCourses");
            const sortedCourses = response.data.courses.sort(sortCourses);
            setCourses(sortedCourses);
        } catch(requestError) {
            Sentry.captureException(requestError);
            console.error(requestError);
            setError(i18next.t("errorGeneral"));
        }
    }, [shopContext]);
    useEffect(() => {
        refreshCourses();
    }, [refreshCourses]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        );
    }
    return (
        <React.Fragment>
            <h3>
                <Trans i18nKey="courses"/>
            </h3>
            { !courses ? (
                [...Array(10)].map((value, index) => (
                    <div key={ index } className="mb-3">
                        <Skeleton height={ 175 }/>
                    </div>
                ))
            ) : courses.length === 0 ? (
                <AccountEmptyStateCard
                    title="noCoursesYet"
                    description="noCoursesYetDescription1"
                    description2="noCoursesYetDescription2"
                />
            ) : courses.map((course) => (
                <AccountCourseCard
                    key={ course.id }
                    course={ course }
                />
            ))}
        </React.Fragment>
    );
}

export default React.memo(AccountCourses);
