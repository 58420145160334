import React, {
    useContext,
    useMemo
} from "react";
import Skeleton from "react-loading-skeleton";
import {
    Trans
} from "react-i18next";

import {
    ProductPageContext
} from "../../context/ProductPageManager";
import ShopContext from "../../context/internal/ShopManager";

function ProductAvailabilityCheckmark({ product, short, className }) {
    const productContext = useContext(ProductPageContext);
    const shopContext = useContext(ShopContext);

    const productInternal = useMemo(() => {
        if(product) {
            return product;
        }
        return productContext?.product;
    }, [productContext, product]);

    if(!productInternal) {
        return (
            <p className={ className }>
                <Skeleton/>
            </p>
        );
    }
    if(productInternal.preorder || !productInternal.orderable || !shopContext.shop?.orderingEnabled) {
        return null;
    }
    return (
        <p className={"text-success" + (className ? ` ${className}` : "")}>
            <i className="fas fa-check-circle mr-2"/>
            <b>
                { (productInternal.type.value === "digital" || productInternal.type.value === "course")? (
                    <Trans i18nKey="availabilityDigital"/>
                ) : productInternal.type.value === "rental" ? (
                    <Trans i18nKey="availabilityRental"/>
                ) : (
                    short ? (
                        <Trans i18nKey="availabilityPhysicalShort"/>
                    ) : (
                        <Trans i18nKey="availabilityPhysicalLong"/>
                    )
                )}
            </b>
        </p>
    );
}

export default React.memo(ProductAvailabilityCheckmark);
